import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

export const PartnerLogoImage: React.FC<PartnerLogoImageProps> = ({ partnerName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let logoImageUrl = "";
  switch (partnerName) {
    case "gsp-airport":
      logoImageUrl = `${process.env.REACT_APP_ASSET_ENDPOINT}/images/gsp-airport/logo.png`;
      break;
    case "omaha":
      logoImageUrl = `${process.env.REACT_APP_ASSET_ENDPOINT}/images/park-omaha/logo-colorx44.png`;
      break;
    case "fortlee":
      logoImageUrl = `${process.env.REACT_APP_ASSET_ENDPOINT}/images/fort-lee/logo.svg`;
      break;
    case "pittsburgh":
      logoImageUrl = `${process.env.REACT_APP_ASSET_ENDPOINT}/images/pittsburgh/logox72`;
      break;
    case "bethlehem":
      logoImageUrl = `${process.env.REACT_APP_ASSET_ENDPOINT}/images/bethlehem/logox88.png`;
      break;
  }

  return <img src={logoImageUrl} style={{ height: isMobile ? 20 : 44 }} alt={`${partnerName} Logo`} />;
};

export interface PartnerLogoImageProps {
  partnerName: string;
}

export default PartnerLogoImage;
