import React from "react";
import TransactionSummaryTitle from "./TransactionSummaryTitle";
import TransactionSummaryLayout from "./TransactionSummaryLayout";

/**
 * Component that displays the transaction summary for a payer account type.
 */
export const PayerTransactionSummary: React.FC = () => {
  return (
    <TransactionSummaryLayout>
      <TransactionSummaryTitle />
    </TransactionSummaryLayout>
  );
};

export default PayerTransactionSummary;
