import Typography from "@mui/material/Typography";
import React from "react";
import { useTheme, Theme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import PartnerLogoImage from "../PartnerLogoImage";

export const MobileHeader: React.FC<MobileHeaderProps> = ({ partnerName = "omaha" }) => {
  const theme = useTheme();

  let headerText = "";

  switch (partnerName) {
    case "omaha":
      headerText = "In partnership with Park Omaha";
      break;
    case "fortlee":
      headerText = "In partnership with Fort Lee";
      break;
    case "pittsburgh":
      headerText = "In partnership with Pittsburgh";
      break;
    case "bethlehem":
      headerText = "In partnership with Bethlehem";
      break;
    case "gsp-airport":
      headerText = "In partnership with GSP Airport";
      break;
    default:
      break;
  }

  return (
    <Stack
      sx={{
        color: theme.palette.primary.white,
        backgroundColor: "#E8E8E8",
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        right: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      direction="row"
      spacing={1}
    >
      <PartnerLogoImage partnerName={partnerName} />
      <Typography variant="caption" color={(theme: Theme) => theme.palette.text.primary}>
        {headerText}
      </Typography>
    </Stack>
  );
};

export interface MobileHeaderProps {
  partnerName?: string;
}

export default MobileHeader;
