import React from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useIsMobile, useUserProfile } from "@app.automotus.io/components/hooks";

export const TransactionSummaryTitle: React.FC = () => {
  const isMobile = useIsMobile();
  const { userProfile, loading } = useUserProfile();

  return (
    <Typography sx={{ mb: 3 }} variant={isMobile ? "h6" : "h4"} fontWeight={isMobile ? 400 : 600}>
      {loading || !userProfile ? (
        <Skeleton width="80%" />
      ) : (
        `Transactions for ${userProfile.account.organizationName || userProfile.account.holderName}`
      )}
    </Typography>
  );
};

export default TransactionSummaryTitle;
