import React from "react";
import PartnerLogoImage from "../PartnerLogoImage";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export const PartnerLandingPageDesktopPartnerBranding: React.FC<PartnerLandingPageDesktopPartnerBrandingProps> = ({
  partnerName,
}) => {
  let text = "";
  const theme = useTheme();

  switch (partnerName) {
    case "fortlee":
      text = "Fort Lee Smart Loading Zone Program";
      break;
    case "pittsburgh":
      text = "Pittsburgh Smart Loading Zone Program";
      break;
    case "bethlehem":
      text = "Bethlehem Smart Loading Zone Program";
      break;
    default:
      break;
  }

  return (
    <>
      <PartnerLogoImage partnerName={partnerName} />
      <Typography variant="h7" sx={{ ml: 1.5, color: theme.palette.primary.black }}>
        {text}
      </Typography>
    </>
  );
};

export interface PartnerLandingPageDesktopPartnerBrandingProps {
  partnerName: string;
}

export default PartnerLandingPageDesktopPartnerBranding;
